<template>
        <v-app-bar app clipped-left flat color="secondary">
            <span style="padding-left: 35px"> </span>
            <span style="color: white; font-size: 1.2em">PORTAL INSTALACIÓNS</span>
            <v-chip
                    class="ml-3 mt-5"
                    color="white"
                    outlined
                    style="width: 70px; height: 15px;"
                    >
                    <span style="color: white; font-size: 0.7em">&nbsp;&nbsp;v 0.1.0</span>
            </v-chip>
            <v-spacer />
        </v-app-bar>
</template>

<script>
export default {
    name: 'App',
    data: () => ({
        // url: process.env.VUE_APP_API_URL,
    }),
    methods: {},
    computed: {},
    watch: {},
    mounted () {},
    created () {}
}
</script>
<style>
.iconosVcards {
  background-color: #363636;
  margin-bottom: 10px;
  padding: 25px;
  border-radius: 3px;
  position: relative;
  top: -25px;
}

.posicionBotonVolver {
  position: relative;
  top: -22px;
}
</style>
